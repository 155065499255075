import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Viewtransition {
  constructor(element) {
    this.element = element;

    this.contentBox = document.querySelector(".o-grid__item--content")

    this.handleClick = this.handleClick.bind(this);
    this.handlePageshow = this.handlePageshow.bind(this);


  }

  handleClick(e) {
    e.preventDefault();
    // cest devenu un fake viewtransition
    let target = e.target;
    this.clickedUrl = target.href;
    this.contentBox.classList.add("is-moveout");

    setTimeout(function() {
      window.location = target.href;
    }, 100);
  }

  handlePageshow(e){
    this.contentBox.classList.remove('is-moveout');
  };


  mount() {
    window.addEventListener('pageshow', this.handlePageshow);
    this.element.addEventListener('click', this.handleClick);
  }

  navigateTo(path) {
  }

  unmount() {
  }
}
